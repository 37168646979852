import { defineMessages } from 'react-intl';

export default defineMessages({
  claimPassengerTitle: {
    id: 'cc4.claim.passenger-list.title',
    defaultMessage: 'Passenger list',
  },
  claimPassengerBackButton: {
    id: 'cc4.claim.passenger-list.back',
    defaultMessage: 'Back',
  },
  claimPassengerSaveButton: {
    id: 'cc4.claim.passenger-list.save-button',
    defaultMessage: 'Save',
  },
  claimPassengerAddNewPassengerButton: {
    id: 'cc4.claim.passenger-list.add-new-passenger-button',
    defaultMessage: '+ Add passenger',
  },
  minorTitle: {
    id: 'cc4.claim.passenger-list.minor-title',
    defaultMessage: '<cta>(minor)</cta>',
  },
  legalGuardianTitle: {
    id: 'cc4.claim.passenger-list.legal-guardian-title',
    defaultMessage: '<cta>(legal guardian)</cta>',
  },
  modalTitle: {
    id: 'cc4.claim.passenger-list.modal-title',
    defaultMessage: 'Remove this passenger?',
  },
  removeButton: {
    id: 'cc4.claim.passenger-list.remove-title',
    defaultMessage: 'Remove',
  },
  cancelButton: {
    id: 'cc4.claim.passenger-list.cancel-title',
    defaultMessage: 'Cancel',
  },
  successMessage: {
    id: 'cc4.claim.passenger-list.success-message',
    defaultMessage: 'Changes successfully saved',
  },
  newPassengerTitle: {
    id: 'cc4.claim.passenger-list.new-passenger',
    defaultMessage: 'New passenger',
  },
});
