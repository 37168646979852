import { defineMessages } from 'react-intl';

export default defineMessages({
  seoTitle: {
    id: 'cc4.seo.passengers.fellow-passengers.title',
    defaultMessage: '',
  },
  seoDescription: {
    id: 'cc4.seo.passengers.fellow-passengers.description',
    defaultMessage: '',
  },
  fellowPassengersTitle: {
    id: 'cc4.form.passengers.fellow-passengers.title',
    defaultMessage: 'Fellow passengers',
  },
  passengerListTitle: {
    id: 'cc4.form.passengers.passengers-list.title',
    defaultMessage: 'Passenger list',
  },
  passengerCount: {
    id: 'cc4.form.passengers.passenger-count',
    defaultMessage: `Passenger {count}`,
  },
  firstNamePlaceholder: {
    id: 'cc4.form.passengers.placeholder.first-name',
    defaultMessage: 'Type first name',
  },
  lastNamePlaceholder: {
    id: 'cc4.form.passengers.placeholder.last-name',
    defaultMessage: 'Type last name',
  },
  phone: {
    id: 'cc4.form.passengers.label.phone',
    defaultMessage: 'Phone (optional)',
  },
  phonePlaceholder: {
    id: 'cc4.form.passengers.placeholder.phone',
    defaultMessage: 'Type number',
  },
  email: {
    id: 'cc4.form.passengers.label.email',
    defaultMessage: 'Email (optional)',
  },
  emailPlaceholder: {
    id: 'cc4.form.passengers.placeholder.email',
    defaultMessage: 'Type email',
  },
  addPassenger: {
    id: 'cc4.form.passengers.button.add-passenger',
    defaultMessage: 'Add Passenger',
  },
  passengersBackButton: {
    id: 'cc4.form.passengers.button.back',
    defaultMessage: 'Back',
  },
  submitContinue: {
    id: 'cc4.form.passengers.button.submit-continue',
    defaultMessage: 'Continue',
  },
  isMinorLabel: {
    id: 'cc4.form.passengers.label.is-minor',
    defaultMessage: 'Minor (under 18 y/o)',
  },
  asideText: {
    id: 'cc4.form.passengers.aside-text',
    defaultMessage: `Tell us the contact details of your fellow passengers. 
    We will be able to collect the documents directly from them and speed up the claiming process.`,
  },
  removePassegerExplaination: {
    id: 'cc4.form.shareable-passenger-remove.description',
    defaultMessage: `If you remove a passenger from the list, they won't be included in the claim.`,
  },
});
