import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { CSSTransition } from 'react-transition-group';

// Components
import Input from '../../components/fields/Input';
import CountrySelect from '../../components/fields/CountrySelect';
import Checkbox from '../../components/fields/Checkbox';

// config
import config from '../../config';

// Translations
import messages from './messages';

// Icon
import SearchIcon from '../../assets/icons/search-icon.inline.svg';

const PersonalInfoB = ({
  isFirstPartValid,
  pageBottomRef,
  country,
  values,
  formatMessage,
}) => {
  const [firstTimeValid, setFirstTimeValid] = useState(false);

  const scrollToBottom = useCallback(() => {
    pageBottomRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [pageBottomRef]);

  useEffect(() => {
    if (!firstTimeValid && isFirstPartValid) {
      setFirstTimeValid(true);
      return;
    }
  }, [isFirstPartValid, firstTimeValid]);

  useEffect(() => {
    if (!isMobile || !firstTimeValid) return;

    const timer = setTimeout(scrollToBottom, 400);

    return () => clearTimeout(timer);
  }, [firstTimeValid, scrollToBottom, pageBottomRef]);

  return (
    <CSSTransition
      in={firstTimeValid}
      // needs to be equal to the transition duration
      timeout={{
        appear: 300,
        enter: 300,
      }}
      classNames="display-"
      unmountOnExit={false}
      mountOnEnter={true}
      appear={true}
      enter={true}
      exit={false}
    >
      <div className="display">
        <div className="fieldset sm:flex-row mt-25">
          <Input
            name="streetname"
            label={messages.address}
            placeholder={messages.addressPlaceholder}
          />
          <Input
            name="postal"
            label={messages.postalCode}
            placeholder={messages.postalCodePlaceholder}
          />
        </div>
        <div className="fieldset sm:flex-row mt-25">
          <Input
            name="city"
            label={messages.city}
            placeholder={messages.cityPlaceholder}
          />
          <CountrySelect
            name="country"
            label={messages.country}
            placeholder={messages.countryPlaceholder}
            code={country}
            icon={<SearchIcon />}
            hideIconOnValue={true}
          />
        </div>
        {!values.customer_is_passenger && (
          <div className="fieldset sm:flex-row mt-25">
            <Checkbox
              full
              name="terms_and_conditions"
              label={formatMessage(messages.termsAndConditions, {
                // eslint-disable-next-line
                cta: (...chunks) => (
                  <a
                    className="link"
                    href={config.termsOfServiceUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chunks}
                  </a>
                ),
                // eslint-disable-next-line
                prices: (...chunks) => (
                  <a
                    className="link"
                    href={config.pricingListUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chunks}
                  </a>
                ),
              })}
            />
          </div>
        )}
      </div>
    </CSSTransition>
  );
};

PersonalInfoB.propTypes = {
  country: PropTypes.string,
  formatMessage: PropTypes.func.isRequired,
  isFirstPartValid: PropTypes.bool.isRequired,
  pageBottomRef: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default PersonalInfoB;
