import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

// Components
import Card from '../../../components/Card';
import SubTitleWithIcon from '../../PersonalInfo/SubTitleWithIcon';
import Input from '../../../components/fields/Input';
import BirthdayPicker from '../../../components/fields/BirthdayPicker/BirthdayPicker';
import PhoneInput from '../../../components/fields/PhoneInput';
import CountrySelect from '../../../components/fields/CountrySelect';

// Icons
import userIcon from '../../../assets/icons/user-icon.inline.svg';
import CollapseIcon from '../../../assets/icons/collapse-icon.inline.svg';
import SearchIcon from '../../../assets/icons/search-icon.inline.svg';

// Config
import config from '../../../config';

// Translations
import messages from '../messages';
import personalInfoMessages from '../../PersonalInfo/messages';
import commonMessages from '../../../messages/common';

const CustomerIsPassenger = ({
  formatMessage,
  collapsed,
  handleCollapse,
  firstname,
  lastname,
  index,
  countryCode,
}) => {
  const country = countryCode || config.country.toUpperCase();
  const locale = config.locale;

  return (
    <Card
      onlyMobile={true}
      className="sm:bg-primary-05"
      // eslint-disable-next-line
      key={`passenger-one`}
    >
      <div className="sm:p-30">
        <div className="fieldset flex-row justify-between">
          <SubTitleWithIcon icon={userIcon} className="fill-primary text-lg">
            {formatMessage(messages.passengerCount, {
              count: 1,
            })}
            {` - ${firstname} ${lastname}`}
          </SubTitleWithIcon>
          <div
            className="inline-flex items-center cursor-pointer"
            onClick={handleCollapse}
          >
            {!collapsed
              ? formatMessage(commonMessages.showMore)
              : formatMessage(commonMessages.showLess)}
            <CollapseIcon
              className="mt-5 ml-5"
              style={{
                transform: !collapsed ? 'rotate(180deg)' : '',
              }}
            />
          </div>
        </div>
        <CSSTransition
          in={collapsed}
          // needs to be equal to the transition duration
          timeout={{
            appear: 300,
            enter: 300,
            exit: 300,
          }}
          classNames="display-"
          unmountOnExit={true}
          mountOnEnter={true}
          appear={true}
          enter={true}
          exit={true}
        >
          <div className="display">
            <div className="fieldset sm:flex-row mt-25">
              <Input
                name={`passengers.${index}.person.first_name`}
                type="text"
                label={personalInfoMessages.firstName}
                placeholder={personalInfoMessages.firstNamePlaceholder}
              />
              <Input
                name={`passengers.${index}.person.last_name`}
                type="text"
                label={personalInfoMessages.lastName}
                placeholder={personalInfoMessages.lastNamePlaceholder}
              />
            </div>
            <div className="fieldset sm:flex-row mt-25">
              <PhoneInput
                name={`passengers.${index}.person.phone`}
                label={personalInfoMessages.phone}
                placeholder={personalInfoMessages.phonePlaceholder}
                portal={country}
                locale={locale}
              />
              <BirthdayPicker
                name={`passengers.${index}.person.date_of_birth`}
                autoComplete="off"
                label={personalInfoMessages.birthDate}
              />
            </div>
            <div className="fieldset sm:flex-row mt-25">
              <Input
                name={`passengers.${index}.person.address`}
                label={personalInfoMessages.address}
                placeholder={personalInfoMessages.addressPlaceholder}
              />
              <Input
                name={`passengers.${index}.person.postal_code`}
                label={personalInfoMessages.postalCode}
                placeholder={personalInfoMessages.postalCodePlaceholder}
              />
            </div>
            <div className="fieldset sm:flex-row mt-25">
              <Input
                name={`passengers.${index}.person.city`}
                label={personalInfoMessages.city}
                placeholder={personalInfoMessages.cityPlaceholder}
              />
              <CountrySelect
                name={`passengers.${index}.person.country`}
                label={personalInfoMessages.country}
                placeholder={personalInfoMessages.countryPlaceholder}
                code={country}
                icon={<SearchIcon />}
                hideIconOnValue={true}
              />
            </div>
          </div>
        </CSSTransition>
      </div>
    </Card>
  );
};

CustomerIsPassenger.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  countryCode: PropTypes.string,
  firstname: PropTypes.string.isRequired,
  formatMessage: PropTypes.func.isRequired,
  handleCollapse: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  lastname: PropTypes.string.isRequired,
};

export default CustomerIsPassenger;
