import * as Sentry from '@sentry/browser';
import { Serializer } from 'jsonapi-serializer';
import { customersSchema } from './customers';
import { getLocalStorageState, PRODUCT_TYPE } from '../appLocalStorage';
import uuid from 'uuid/v4';
import config from '../../config';

const relationshipToTypeMapClaims = {
  visaCardIssuer: 'organizations',
};

const claimSchema = {
  attributes: ['product_type', 'visa_card_type', 'visaCardIssuer'],
  visaCardIssuer: {
    ref: 'id',
  },
  typeForAttribute: attribute => relationshipToTypeMapClaims[attribute],
};
const personSchema = {
  attributes: [
    'first_name',
    'last_name',
    'first_name',
    'last_name',
    'email',
    'phone',
    'date_of_birth',
    'address',
    'city',
    'postal_code',
    'city_of_birth',
    'signature',
    'country',
    'countryOfBirth',
  ],
  country: { ref: 'id' },
  countryOfBirth: { ref: 'id' },
};

const relationshipToTypeMap = {
  arrivalAirport: 'airports',
  departureAirport: 'airports',
};

const customerFlightsSchema = {
  attributes: [
    'type',
    'flight_code',
    'flight_date',
    'flight_status',
    'arrivalAirport',
    'departureAirport',
    'airline',
  ],
  arrivalAirport: { ref: 'id' },
  departureAirport: { ref: 'id' },
  airline: { ref: 'id' },
  typeForAttribute: attribute => relationshipToTypeMap[attribute],
};

function serializeJSONAPI(type, data, schema) {
  return data
    ? new Serializer(type, {
        ...(schema || {}),
        keyForAttribute: attribute => attribute,
      }).serialize(data)
    : undefined;
}

export const atomicOperationSubmitClaim = (
  customerFlights,
  alternativeFlights,
  signature,
  isLoggedIn
) => {
  const lidClaim = uuid();
  const lidPerson = uuid();
  const lidPassenger = uuid();
  const payload = {
    'atomic:operations': [],
  };

  if (!isLoggedIn) {
    payload['atomic:operations'].push(createCustomerData());
  }

  payload['atomic:operations'].push(createClaimsData(lidClaim));
  payload['atomic:operations'].push(createPersonData(lidPerson, signature));
  payload['atomic:operations'].push(
    createPassengerData(lidPassenger, lidClaim, lidPerson)
  );
  customerFlights.forEach((flight, index) => {
    payload['atomic:operations'].push(
      createFlightsData(flight, lidClaim, 'booked', index)
    );
  });
  if (alternativeFlights?.length) {
    alternativeFlights?.forEach(flight => {
      payload['atomic:operations'].push(
        createFlightsData(flight, lidClaim, 'alternative')
      );
    });
  }

  return payload;
};

const createCustomerData = () => {
  const customerData = getLocalStorageState('personalInfo');
  customerData.email = getLocalStorageState('compensationCheck').email;
  customerData.country = { id: customerData?.country };
  const personalInfo = serializeJSONAPI(
    'customers',
    customerData,
    customersSchema
  );
  personalInfo.op = 'add';
  personalInfo.href = '/customers';

  return personalInfo;
};

const createClaimsData = lid => {
  const passenger_count =
    getLocalStorageState('compensationCheck')?.passengers || 1;
  const productType = {
    product_type: getLocalStorageState(PRODUCT_TYPE),
  };
  const cobrandingDossierTokenId = getLocalStorageState(
    'cobrandingDossierTokenId'
  );
  const visa_card_type = localStorage.getItem('yo:visa_card_type');
  const visaCardIssuer = localStorage.getItem('yo:visaCardIssuer');
  if (visa_card_type) {
    productType.visa_card_type = visa_card_type;
  }

  if (visaCardIssuer && visaCardIssuer !== 'undefined') {
    productType.visaCardIssuer = { id: visaCardIssuer };
  }

  const cobrandingDossierToken = cobrandingDossierTokenId
    ? {
        cobrandingDossierToken: {
          data: {
            type: 'cobrandingDossierTokens',
            id: cobrandingDossierTokenId,
          },
        },
      }
    : {};

  const claimInfo = serializeJSONAPI('claims', productType, claimSchema);
  claimInfo.op = 'add';
  claimInfo.href = '/claims';
  claimInfo['data'].lid = lid;
  claimInfo['data'].meta = {
    passenger_count,
  };
  claimInfo['data'].relationships = {
    ...claimInfo['data'].relationships,
    ...cobrandingDossierToken,
  };

  return claimInfo;
};

const createPersonData = (lid, signature) => {
  const personalInfo = getLocalStorageState('personalInfo');
  personalInfo.email = getLocalStorageState('compensationCheck').email;
  const personData = {
    first_name: personalInfo.firstname,
    last_name: personalInfo.lastname,
    email: personalInfo.email,
    phone: personalInfo.phone,
    date_of_birth: personalInfo.birthday,
    address: personalInfo.streetname,
    city: personalInfo.city,
    postal_code: personalInfo.postal,
    city_of_birth: personalInfo.place_of_birth,
    signature,
    country: { id: personalInfo?.country },
    countryOfBirth: { id: personalInfo?.country_of_birth },
  };
  const personInfo = serializeJSONAPI('persons', personData, personSchema);
  personInfo.op = 'add';
  personInfo.href = '/persons';
  personInfo['data'].lid = lid;

  return personInfo;
};

const createPassengerData = (lid, lidClaim, lidPerson) => {
  const passengerInfo = {
    op: 'add',
    href: '/passengers',
    data: {
      type: 'passengers',
      lid,
      attributes: [],
      relationships: {
        claim: {
          data: {
            type: 'claims',
            lid: lidClaim,
          },
        },
        person: {
          data: {
            type: 'persons',
            lid: lidPerson,
          },
        },
      },
    },
  };

  return passengerInfo;
};

const createFlightsData = (flight, lidClaim, type, index = null) => {
  const { flightDisruptions = [] } =
    getLocalStorageState('flightDisruptions') || {};

  // if there is no flght number send error to Sentry
  if (!flight?.flight_number) {
    const env = config?.portalUrl?.includes('test') ? 'TEST' : 'PROD';
    const flightLocalStorage = JSON.parse(
      localStorage.getItem('yo:globalState')
    )?.value?.customerFlights;

    // @ts-ignore
    Sentry?.captureMessage(`[${env}]: Flight number debug`, {
      level: Sentry.Severity.Info,
      extra: {
        flightLocalStorageData: JSON.stringify(flightLocalStorage),
        incomingFlightDate: flight,
      },
    });
  }

  const customerFlightsData = {
    flight_code:
      flight?.flight_code || flight.airline_code + flight.flight_number,
    flight_date: flight.flight_date,
    flight_status: type === 'booked' ? flightDisruptions[index] || null : null,
    type,
    airline: { id: flight.airline_id },
    arrivalAirport: { id: flight.arrival_airport_id },
    departureAirport: { id: flight.departure_airport_id },
  };

  const customerFlightsInfo = serializeJSONAPI(
    'customerFlights',
    customerFlightsData,
    customerFlightsSchema
  );
  customerFlightsInfo.href = '/customerFlights';
  customerFlightsInfo.op = 'add';
  customerFlightsInfo.data.relationships.claim = {
    data: { lid: lidClaim, type: 'claims' },
  };

  return customerFlightsInfo;
};
