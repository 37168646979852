import React from 'react';
import PropTypes from 'prop-types';

// Translations
import messages from './messages';

const CustomerIsPassenger = ({
  className,
  values,
  setFieldValue,
  formatMessage,
}) => {
  return (
    <p className={`m-0 ${className}`}>
      {formatMessage(
        values.customer_is_passenger
          ? messages.notPassenger
          : messages.areYouPassenger,
        {
          // eslint-disable-next-line react/no-multi-comp
          cta: (...chunks) => (
            <span
              className="link"
              // eslint-disable-next-line
              onClick={() => {
                setFieldValue(
                  'customer_is_passenger',
                  !values['customer_is_passenger']
                );
              }}
            >
              {chunks}
            </span>
          ),
        }
      )}
    </p>
  );
};

CustomerIsPassenger.propTypes = {
  className: PropTypes.string,
  formatMessage: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default CustomerIsPassenger;
