import React from 'react';
import { useIntl } from 'react-intl';
import '../../utils/yupIntlSetup';

// Components
import Container from '../../components/Container';
import Aside from '../../components/Aside';
import Main from '../../components/Main';
import Icon from '../../components/Icon';

// Translations
import messages from './messages';

// Icons
import penIcon from '../../assets/icons/pen-icon.svg';

import Seo from '../../components/seo';
import routes from '../../messages/routes';
import StepObserver from '../../components/StepObserver';
import { steps, useTranslatedNavigate } from '../../utils/routes';
import SignPermission from './SignPermission';
import TrustBox from '../../components/TrustBox';
import SocialProof from '../../components/SocialProof';
import SocialProofMobile from '../../components/SocialProof/Mobile';

// eslint-disable-next-line
const Permission = () => {
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();
  const claimId = localStorage.getItem('yo:claimId');

  function navigateBack() {
    translatedNavigate([`/`, routes.congratulationsBasepath]);
  }

  function navigateNext(data) {
    translatedNavigate(
      [
        '/',
        routes.congratulationsBasepath,
        data?.claim_number,
        routes.questionnaire,
      ],
      {
        state: { data },
      }
    );
  }

  return (
    <Container>
      <StepObserver stepName={steps.Permission} />
      <Seo title={messages.seoTitle} description={messages.seoDescription} />
      <Main>
        <SignPermission
          withNavigation
          claimId={claimId}
          navigateBack={navigateBack}
          navigateNext={navigateNext}
        />
      </Main>
      <Aside showSocialProof>
        <SocialProof>
          <Icon src={penIcon} className="mb-20" />
          {formatMessage(messages.asideText)}
        </SocialProof>
      </Aside>
      <TrustBox className="mb-20" />
      <SocialProofMobile />
    </Container>
  );
};

export default Permission;
