import React from 'react';

// Components
import PersonalInfo from '../../views/PersonalInfo';
import PassengerList from '../../views/Passengers/PassengerList';
import Permission from '../../views/Permission';
import BookingNumber from '../../views/BookingNumber';
import PageTransition from '../../components/PageTransition';
import QuestionnaireContainer from '../../views/Questionnaire/QuestionnaireContainer';
import routes from '../../messages/routes';
import ClaimAmount from '../../views/ClaimAmount';
import DirectFeedbackUploadStep from '../../views/DirectFeedbackUpload/DirectFeedbackUploadStep';
import translations from '../../translations/main.json';
import Claim from '../../views/ClaimPage/Claim';
import PassengerContainer, {
  PageType,
} from '../../views/Passengers/PassengerContainer';
import PassengerStep from '../../views/Passengers/PassengerStep';
import NotFoundPage from '../../pages/404';
import LegalGuardianStep from '../../views/LegalGuardian/LegalGuardianStep';
import AdditionalCostAlternativeFlight from '../../views/AdditionalCostAlternativeFlight';

/**
 * NOT CHANGE THE ORDER HERE !
 * (To be sure with navigation look at a component and the navigation hooks)
 */

const CongratulationsPage = () => (
  <PageTransition basepath={routes.congratulationsBasepath} hideMenu>
    <PersonalInfo path="/" />
    {/* 
      if customer_is_passenger it goes to permission and after permission to questionnaire
      if !customer_is_passenger it skips permission and goes to questionnaire
     */}
    <Permission path={routes.permission} />
    <Claim path=":claimNumber" redirect={false} pageType={PageType.Funnel}>
      <QuestionnaireContainer
        path={translations['cc4.seo.route.questionnaire']}
      />
      <PassengerList path={translations['cc4.seo.route.passengers-list']} />
      <PassengerContainer
        pageType={PageType.Funnel}
        path={translations['cc4.seo.route.passenger']}
      />
      <PassengerContainer
        pageType={PageType.Funnel}
        path={translations['cc4.seo.route.passenger'] + '/:passengerId'}
        redirect={false}
      >
        <PassengerStep path="/" pageType={PageType.Funnel} />
        <LegalGuardianStep
          pageType={PageType.Funnel}
          path={
            translations['cc4.seo.route.legal-guardian-info'] +
            '/:guardianIndex'
          }
        />
      </PassengerContainer>
      <DirectFeedbackUploadStep
        pageType={PageType.Funnel}
        path={'/:documentTypeRouteParam'}
      />
      {/*
      if productType = FLIGHT_COMPENSATION it goes to bookingReference
      if productType = TICKET_REFUND it goes to claimAmount
     */}
      <AdditionalCostAlternativeFlight
        pageType={PageType.Funnel}
        path={translations['cc4.seo.route.upload-additional-costs']}
      />
      <ClaimAmount path={translations['cc4.seo.route.claim-amount']} />
      <BookingNumber path={translations['cc4.seo.route.booking-reference']} />
    </Claim>
    <NotFoundPage default />
  </PageTransition>
);

CongratulationsPage.propTypes = {};

export default CongratulationsPage;
