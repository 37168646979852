import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FieldArray, Form, Formik, yupToFormErrors } from 'formik';
import '../../../utils/yupIntlSetup';
import * as yup from 'yup';
import { isMobile } from 'react-device-detect';

// Components
import Card from '../../../components/Card';
import Button from '../../../components/Button';
import FormikPersist from '../../../components/fields/FormikPersist';
import StepNavigation from '../../../components/StepNavigation';
import Input from '../../../components/fields/Input';
import Container from '../../../components/Container';
import Aside from '../../../components/Aside';
import Icon from '../../../components/Icon';
import Main from '../../../components/Main';
import PhoneInput from '../../../components/fields/PhoneInput';
import SubTitleWithIcon from '../../PersonalInfo/SubTitleWithIcon';
import StepTitle from '../../../components/StepTitle';
import Checkbox from '../../../components/fields/Checkbox';
import CustomerIsPassenger from './CustomerIsPassenger';
import TrustBox from '../../../components/TrustBox';
import InfoOverlay from '../../../components/InfoOverlay';
import { RequirementComponents, name } from '../../SharablePageWrapper';

// Hooks
import { useLocalStorageState } from '../../../hooks/useLocalStorageState';

// Translations
import messages from '../messages';
import commonMessages from '../../../messages/common';
import personalInfoMessages from '../../PersonalInfo/messages';
import passengerListMessages from '../../Account/ClaimDetails/ClaimPassengerList/claimPassengerListMessages';
import routes from '../../../messages/routes';
import translations from '../../../translations/main.json';

// Utils
import {
  createPassenger,
  deletePassenger,
} from '../../../services/resources/passengers';
import {
  createPerson,
  updatePerson,
} from '../../../services/resources/persons';
import config from '../../../config';
import {
  validateBirthday,
  validateFirstname,
  validateLastname,
  validatePhoneNumber,
} from '../../../utils/yupValidators';
import Seo from '../../../components/seo';

// Icons
import shareIcon from '../../../assets/icons/share-icon.svg';
import userIcon from '../../../assets/icons/user-icon.inline.svg';
import BinIcon from '../../../assets/icons/bin-icon.inline.svg';
import StepObserver from '../../../components/StepObserver';
import { deserializeJSONAPI, useHandleError } from '../../../services/api';

// Model
import { PassengerModel } from '../../../models/PassengerModel';
import { isEmpty } from 'lodash';
import { useJsonApi } from '../../../services/GlobalStore/GlobalStore';
import Loading from '../../../components/Loading';
import { steps, useTranslatedNavigate } from '../../../utils/routes';

import { sortPassengers } from '../PassengerContainer';

const schema = yup.object().shape({
  passengers: yup
    .array()
    .of(
      yup.lazy((item: PassengerModel) => {
        if (item?.main_booker === true) {
          return yup.object().shape({
            person: yup.object({
              first_name: validateFirstname,
              last_name: validateLastname,
              phone: validatePhoneNumber.required(),
              date_of_birth: validateBirthday.required(),
              address: yup.string().required(),
              postal_code: yup.string().required(),
              city: yup.string().required(),
              country: item?.person?.country?.code
                ? yup.object().required()
                : yup.string().required(),
            }),
          });
        } else {
          return yup.object().shape({
            person: yup.object({
              first_name: validateFirstname,
              last_name: validateLastname,
              phone: validatePhoneNumber.notRequired().nullable(true),
              email: yup
                .string()
                .email()
                .nullable(),
              is_minor: yup.boolean(),
            }),
          });
        }
      })
    )
    .when(`$customerIsPassenger`, (isPassenger, schemaObject) => {
      return isPassenger === true
        ? schemaObject.notRequired().nullable()
        : schemaObject.required().nullable();
    }),
});

const validate = (values: {}, customerIsPassenger: boolean) => {
  return (
    schema
      .validate(values, { abortEarly: false, context: { customerIsPassenger } })
      // without then it resolves and passes values as error messages
      .then(() => undefined)
      .catch(err => yupToFormErrors(err))
  );
};

const PassengerList = () => {
  const { formatMessage } = useIntl();
  const [collapsed, setCollapsed] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isReadyToRemove, setIsReadyToRemove] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  // Get the claim id
  const claimId = localStorage.getItem('yo:claimId');

  const [, setPassengersIds, getPassengersIds] = useLocalStorageState(
    `passengersIds-${claimId}`
  );

  useEffect(() => {
    setPassengersIds([]);
    // eslint-disable-next-line
  }, []);

  const { data: claim } = useJsonApi(
    `/claims/${claimId}`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: ['customer', 'placeOfJurisdiction'],
      },
    },
    [claimId]
  );

  const translatedNavigate = useTranslatedNavigate();

  const [{ passengers } = {}, setLocalStoragePassengers] = useLocalStorageState(
    `passengerList-${claimId}`
  );
  const {
    data: dbPassengers,
    get: getPassengers,
    loading,
    isLoaded,
  } = useJsonApi(
    `/claims/${claimId}/passengers`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: ['person.country'].join(),
      },
    },
    []
  );

  const {
    data: requirementsData,
    loading: requirementsLoading,
    get: fetchRequirementsComponents,
  } = useJsonApi(
    `claims/${claimId}/requirementComponents`,
    {
      cachePolicy: 'no-cache',
    },
    []
  );

  const claimObject = Array.isArray(claim) ? claim[0] : claim;
  const passengerCountInLocalStorage = passengers?.length;
  const passengerCountInDb = dbPassengers?.length;
  const passengerCountAccordingToCustomer = claimObject?.meta?.passenger_count;
  let totalPassengers = passengerCountAccordingToCustomer;
  if (
    passengerCountInLocalStorage === undefined &&
    passengerCountAccordingToCustomer > passengerCountInDb
  ) {
    totalPassengers = passengerCountAccordingToCustomer - passengerCountInDb;
  }
  if (passengerCountInDb >= passengerCountAccordingToCustomer) {
    totalPassengers = 0;
  }

  const initialPassengers =
    !isEmpty(passengers) && isLoaded
      ? passengers
      : new Array(totalPassengers).fill({});

  const initialValues = {
    passengers: dbPassengers?.concat(
      initialPassengers.filter((passenger: PassengerModel) => !passenger?.id)
    ),
  };
  const mainBooker = initialValues?.passengers?.filter(
    (passenger: PassengerModel) => passenger?.main_booker === true
  );
  const customer_is_passenger = !isEmpty(mainBooker);

  const locale = config.locale;
  const countryCode = config.country.toUpperCase();
  const [handleError] = useHandleError();

  function navigateBack() {
    translatedNavigate(routes.questionnaire);
  }

  function handleCollapse() {
    setCollapsed(prevState => !prevState);
  }

  function handleSubmit(values, { setSubmitting }) {
    setFormSubmitting(true);
    if (values && values?.passengers?.length) {
      Promise.all(
        values.passengers.map(async (passenger: PassengerModel) => {
          // Make difference if a passenger needs to be updated or created
          if (passenger.id) {
            await updatePerson({
              personId: passenger.person.id,
              values: passenger.person,
            });
          } else {
            const { id: personId } = await createPerson({
              ...passenger.person,
            });

            await createPassenger({
              claim: { id: claimId },
              person: { id: personId },
            });
          }
        })
      )
        .then(async () => {
          setLocalStoragePassengers([]);
          const updatedPassengers: PassengerModel[] = await getPassengers();
          const requirementComponents = await fetchRequirementsComponents();
          const deserializedPassengers = await deserializeJSONAPI(
            updatedPassengers
          );
          const deserializedRequirementComponents = await deserializeJSONAPI(
            requirementComponents
          );

          navigateForward(
            deserializedPassengers,
            deserializedRequirementComponents
          );
        })
        .catch(error => {
          handleError(error);
          setSubmitting(false);
        });
    } else {
      navigateForward();
    }
  }

  async function navigateForward(
    updatedPassengers?: PassengerModel[],
    requirementComponents?: []
  ) {
    if (updatedPassengers?.length) {
      const sortedPassengers = updatedPassengers
        .sort(sortPassengers)
        .filter(
          passenger =>
            passenger?.person?.is_minor === true ||
            passenger?.main_booker === true
        )
        .map((passenger: PassengerModel) => passenger.id);
      setPassengersIds(sortedPassengers);

      const components = Object.keys(RequirementComponents)
        .map(key => {
          const requirements = requirementComponents || requirementsData;
          const requirement =
            requirements &&
            requirements?.find((req: { id: string }) => req.id === key);

          if (requirement) {
            return RequirementComponents[key as name];
          }
        })
        .filter(Boolean);

      const componentRoute = components[0];
      const passengersWithMissingFields = updatedPassengers.filter(
        (passenger: PassengerModel) => {
          if (
            passenger?.person?.missing_fields?.length &&
            (passenger?.person?.is_minor === true ||
              passenger?.main_booker === true)
          ) {
            return passenger;
          }
        }
      );

      if (!componentRoute) {
        setFormSubmitting(false);
        translatedNavigate([
          '/',
          routes[steps.CongratulationsBasepath],
          claimObject?.claim_number,
          routes[steps.Passenger],
        ]);
        return;
      }
      if (
        componentRoute === translations['cc4.seo.route.passenger'] &&
        passengersWithMissingFields?.length
      ) {
        setFormSubmitting(false);
        translatedNavigate(
          [
            '/',
            routes[steps.CongratulationsBasepath],
            claimObject?.claim_number,
            routes[steps.Passenger],
            passengersWithMissingFields?.[0]?.id,
          ],
          { state: { passengerIndex: 0 }, replace: true }
        );
        return;
      }
      setFormSubmitting(false);
      translatedNavigate([
        '/',
        routes[steps.CongratulationsBasepath],
        claimObject?.claim_number,
        componentRoute,
      ]);
    }
  }

  const handleRemovePassenger = (
    arrayHelpers: any,
    passenger: PassengerModel,
    index: number
  ) => {
    if (passenger?.id) {
      deletePassenger({
        passengerId: passenger.id,
      });
    } else {
      arrayHelpers.remove(index);
    }
    setSelectedIndex(0);
  };

  return (loading || requirementsLoading) && !formSubmitting ? (
    <div className="flex justify-center mt-40">
      <Loading />
    </div>
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      // eslint-disable-next-line
      validate={values => validate(values, customer_is_passenger)}
      isInitialValid={schema.isValidSync(initialValues, customer_is_passenger)}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {({ values }) => (
        <Container>
          <StepObserver stepName={steps.PassengersList} />
          <Seo
            title={messages.seoTitle}
            description={messages.seoDescription}
          />
          <Main>
            <Form>
              <Card onlyDesktop={true}>
                <div className="flex justify-center sm:justify-between items-center">
                  <StepTitle>
                    {formatMessage(
                      customer_is_passenger
                        ? messages.fellowPassengersTitle
                        : messages.passengerListTitle
                    )}
                  </StepTitle>
                  <InfoOverlay
                    title={
                      customer_is_passenger
                        ? messages.fellowPassengersTitle
                        : messages.passengerListTitle
                    }
                    description={messages.asideText}
                  />
                </div>
                <FieldArray
                  name="passengers"
                  // eslint-disable-next-line
                  render={arrayHelpers => (
                    <div>
                      {values?.passengers?.length > 0
                        ? values.passengers.map(
                            (passenger: PassengerModel, index: number) =>
                              customer_is_passenger &&
                              passenger?.main_booker === true ? (
                                <CustomerIsPassenger
                                  formatMessage={formatMessage}
                                  collapsed={collapsed}
                                  handleCollapse={handleCollapse}
                                  firstname={passenger?.person?.first_name}
                                  lastname={passenger?.person?.last_name}
                                  index={index}
                                  countryCode={passenger?.person?.country?.code}
                                />
                              ) : (
                                <Card
                                  onlyMobile={true}
                                  className="sm:bg-primary-05 sm:mt-35 mt-30"
                                  // eslint-disable-next-line
                                  key={`passenger-${index}`}
                                >
                                  <div className="sm:p-30">
                                    {isReadyToRemove &&
                                    selectedIndex === index &&
                                    selectedIndex > 0 ? (
                                      <div
                                        className=" mb-30 rounded sm:shadow sm:px-20 sm:py-30 sm:bg-white"
                                        // eslint-disable-next-line
                                        key={`passenger-${index}`}
                                      >
                                        <div style={{ textAlign: 'center' }}>
                                          <SubTitleWithIcon
                                            icon={userIcon}
                                            width={30}
                                            className="fill-primary text-lg justify-center"
                                          >
                                            {formatMessage(
                                              messages.passengerCount,
                                              {
                                                count: index + 1,
                                              }
                                            )}
                                          </SubTitleWithIcon>
                                          <p
                                            className="justify-center"
                                            style={
                                              isMobile
                                                ? { width: '100%' }
                                                : {
                                                    width: '70%',
                                                    margin: '0 auto',
                                                  }
                                            }
                                          >
                                            {formatMessage(
                                              messages.removePassegerExplaination
                                            )}
                                          </p>
                                          <div className="mt-15 flex sm:block justify-around">
                                            <Button
                                              type="button"
                                              theme="secondary"
                                              size="small"
                                              style={
                                                isMobile ? { width: '40%' } : {}
                                              }
                                              className="hover:bg-primary-400 hover:border-primary-400 width-[5%]"
                                              onClick={() =>
                                                setIsReadyToRemove(false)
                                              }
                                            >
                                              {formatMessage(
                                                passengerListMessages.cancelButton
                                              )}
                                            </Button>
                                            <Button
                                              type="button"
                                              theme="primary"
                                              style={
                                                isMobile ? { width: '40%' } : {}
                                              }
                                              size="small"
                                              showIcon={false}
                                              onClick={() =>
                                                handleRemovePassenger(
                                                  arrayHelpers,
                                                  passenger,
                                                  index
                                                )
                                              }
                                            >
                                              {formatMessage(
                                                commonMessages.remove
                                              )}
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="fieldset flex-row justify-between">
                                        <SubTitleWithIcon
                                          icon={userIcon}
                                          width={30}
                                          className="fill-primary text-lg"
                                        >
                                          {formatMessage(
                                            messages.passengerCount,
                                            {
                                              count: index + 1,
                                            }
                                          )}
                                        </SubTitleWithIcon>
                                        <button
                                          type="button"
                                          className="outline-none focus:outline-none button px-10 border rounded-full text-primary-400 border-primary-400 hover:bg-white font-open-sans cursor-pointer"
                                          // eslint-disable-next-line
                                          onClick={() => {
                                            setSelectedIndex(index);
                                            setIsReadyToRemove(true);
                                          }}
                                        >
                                          <BinIcon
                                            alt="Trash icon"
                                            className="inline w-10 h-13 mr-5 fill-primary"
                                          />
                                          {formatMessage(commonMessages.remove)}
                                        </button>
                                      </div>
                                    )}
                                    <div className="fieldset sm:flex-row">
                                      <Input
                                        name={`passengers.${index}.person.first_name`}
                                        type="text"
                                        label={personalInfoMessages.firstName}
                                        placeholder={
                                          messages.firstNamePlaceholder
                                        }
                                      />
                                      <Input
                                        name={`passengers.${index}.person.last_name`}
                                        type="text"
                                        label={personalInfoMessages.lastName}
                                        placeholder={
                                          messages.lastNamePlaceholder
                                        }
                                      />
                                    </div>
                                    <div className="fieldset sm:flex-row mt-25">
                                      <Input
                                        name={`passengers.${index}.person.email`}
                                        label={messages.email}
                                        placeholder={messages.emailPlaceholder}
                                      />
                                      <PhoneInput
                                        name={`passengers.${index}.person.phone`}
                                        label={messages.phone}
                                        placeholder={messages.phonePlaceholder}
                                        portal={countryCode}
                                        locale={locale}
                                      />
                                    </div>
                                    <div className="fieldset sm:flex-row mt-25">
                                      <Checkbox
                                        name={`passengers.${index}.person.is_minor`}
                                        label={messages.isMinorLabel}
                                      />
                                    </div>
                                  </div>
                                </Card>
                              )
                          )
                        : null}
                      <div className="mt-30 sm:mx-0 mx-15 flex sm:inline-flex flex-col-reverse sm:flex-row w-auto sm:w-auto">
                        <Button
                          type="button"
                          theme="secondary"
                          size="small"
                          className="hover:bg-primary-400 hover:border-primary-400"
                          // eslint-disable-next-line
                          onClick={() => arrayHelpers.push({})}
                        >
                          {'+ '}
                          {formatMessage(messages.addPassenger)}
                        </Button>
                      </div>
                    </div>
                  )}
                />
              </Card>
              <StepNavigation
                onBackClick={navigateBack}
                backButtonMessage={formatMessage(messages.passengersBackButton)}
                submitButtonMessage={formatMessage(messages.submitContinue)}
                dataLayerPushOnBack={true}
                dataLayerPushOnContinue={true}
                step={steps.PassengersList}
              />
              <FormikPersist
                localStorageKey={`passengerList-${claimId}`}
                favorInitialValues={true}
              />
            </Form>
          </Main>
          <Aside>
            <Icon src={shareIcon} className="mb-20" />
            {formatMessage(messages.asideText)}
          </Aside>
          <TrustBox />
        </Container>
      )}
    </Formik>
  );
};

export default PassengerList;
